import React, { useState, useMemo } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import PhoneInputWithCountrySelect from 'react-phone-number-input'

import './Checkout.css'
import config from '../config'
import { Button, SuccessCard, ToggleSwitch, ToggleButton } from '../components'

import cn from 'classnames'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: yup.string().optional(),
    oneTimeCode: yup
        .string()
        .required('Enter your referral code')
        .matches(/^[A-Z0-9-]{6}$/, 'Invalid referral code'),
    tier: yup
        .mixed<Tier>()
        .oneOf(['digitalAmbassador', 'student', 'foundingMember'])
        .required(),
    billingCycle: yup
        .mixed<Frequency>()
        .oneOf(['yearly', 'monthly'])
        .required(),
    receiveNotifications: yup.boolean().required(),
})

type Frequency = 'monthly' | 'yearly'
type Tier = 'digitalAmbassador' | 'student' | 'foundingMember'

const frequencies: Frequency[] = ['monthly', 'yearly']
const tiers: { label: string; tier: Tier }[] = [
    { label: 'Founding Citizen', tier: 'foundingMember' },
    { label: 'Digital Ambassador', tier: 'digitalAmbassador' },
    { label: 'Scholarship', tier: 'student' },
]

type FormValues = {
    firstName: string
    lastName: string
    email: string
    phoneNumber?: string
    oneTimeCode: string
    tier: Tier
    billingCycle: Frequency
    receiveNotifications: boolean
}

const cardElementOptions = {
    style: {
        base: {
            fontSize: '12px',
            '@media (min-width:768px)': {
                fontSize: '16px',
            },
        },
    },
}

const Checkout: React.FC = () => {
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [formError, setFormError] = useState<string>()

    const formMethods = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            billingCycle: 'monthly',
            tier: 'foundingMember',
            receiveNotifications: true,
        },
    })
    const {
        setValue,
        handleSubmit,
        formState: { isValidating, errors },
        watch,
        register,
    } = formMethods

    const [
        billingCycle,
        firstName,
        lastName,
        email,
        phoneNumber,
        oneTimeCode,
        tier,
        receiveNotifications,
    ] = watch([
        'billingCycle',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'oneTimeCode',
        'tier',
        'receiveNotifications',
    ])

    const priceId = useMemo(() => {
        const priceIds = {
            digitalAmbassador: {
                monthly: config.priceIdDigitalAmbassadorMonthly,
                yearly: config.priceIdDigitalAmbassadorYearly,
            },
            student: {
                monthly: config.priceIdStudentMonthly,
                yearly: config.priceIdStudentYearly,
            },
            foundingMember: {
                monthly: config.priceIdFoundingMemberMonthly,
                yearly: config.priceIdFoundingMemberYearly,
            },
        }
        return priceIds?.[tier]?.[billingCycle]
    }, [tier, billingCycle])

    const price = useMemo(() => {
        const prices = {
            student: { monthly: '$120', yearly: '$1200' },
            digitalAmbassador: { monthly: '$55', yearly: '$528' },
            foundingMember: { monthly: '$190', yearly: '$1800' },
        }
        return prices?.[tier]?.[billingCycle]
    }, [tier, billingCycle])

    const onSubmit: SubmitHandler<FormValues> = async () => {
        setLoading(true)
        setFormError(undefined)

        if (!stripe || !elements) {
            setFormError('Stripe is not initialized')
            setLoading(false)
            return
        }

        const cardElement = elements.getElement(CardElement)
        if (!cardElement) {
            setFormError('Card details are not available.')
            setLoading(false)
            return
        }

        try {
            // Step 1: Make the API call to your backend to start payment processing
            const response = await fetch(`${config.apiUrl}/pay/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phone: phoneNumber,
                    oneTimeCode,
                    priceId,
                    receiveNotifications,
                }),
            })

            const responseData = await response.json()

            if (!response.ok) {
                throw new Error(
                    responseData.error || 'An error occurred. Please try again.'
                )
            }

            const { clientSecret, subscriberUuid } = responseData

            // Step 2: Confirm the card payment using the client secret from backend
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: `${firstName} ${lastName}`,
                        email,
                    },
                },
            })

            // Step 3: Handle the result
            if (result.error) {
                // Payment failed
                await fetch(`${config.apiUrl}/set-initial-payment-status/`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        uuid: subscriberUuid,
                        initialPaymentSuccessful: false,
                    }),
                })
                throw new Error(result.error.message || 'Payment failed.')
            } else {
                // Payment processed (could be successful or require additional actions)
                const paymentIntent = result.paymentIntent

                if (paymentIntent.status === 'succeeded') {
                    // Payment succeeded
                    await fetch(
                        `${config.apiUrl}/set-initial-payment-status/`,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                uuid: subscriberUuid,
                                initialPaymentSuccessful: true,
                            }),
                        }
                    )
                    setIsSuccess(true)
                } else if (paymentIntent.status === 'requires_action') {
                    // Handle 3D Secure authentication in modal if possible
                    const confirmResult =
                        await stripe.confirmCardPayment(clientSecret)
                    if (confirmResult.error) {
                        // Authentication failed
                        await fetch(
                            `${config.apiUrl}/set-initial-payment-status/`,
                            {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    uuid: subscriberUuid,
                                    initialPaymentSuccessful: false,
                                }),
                            }
                        )
                        throw new Error(
                            confirmResult.error.message ||
                                'Authentication failed.'
                        )
                    } else if (
                        confirmResult.paymentIntent &&
                        confirmResult.paymentIntent.status === 'succeeded'
                    ) {
                        // Payment succeeded after authentication
                        await fetch(
                            `${config.apiUrl}/set-initial-payment-status/`,
                            {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    uuid: subscriberUuid,
                                    initialPaymentSuccessful: true,
                                }),
                            }
                        )
                        setIsSuccess(true)
                    } else {
                        // Payment failed after authentication
                        await fetch(
                            `${config.apiUrl}/set-initial-payment-status/`,
                            {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    uuid: subscriberUuid,
                                    initialPaymentSuccessful: false,
                                }),
                            }
                        )
                        throw new Error('Payment failed after authentication.')
                    }
                } else {
                    // Other statuses
                    await fetch(
                        `${config.apiUrl}/set-initial-payment-status/`,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                uuid: subscriberUuid,
                                initialPaymentSuccessful: false,
                            }),
                        }
                    )
                    throw new Error('Payment failed. Please try again.')
                }
            }
        } catch (error: any) {
            setFormError(
                error.message ||
                    'An unexpected error occurred. Please try again.'
            )
        } finally {
            setLoading(false)
        }
    }

    const renderFormField = ({
        name,
        placeholder,
    }: {
        name: keyof FormValues
        placeholder: string
    }) => (
        <div className="space-y-1 flex-1">
            <input
                type="text"
                {...register(name)}
                placeholder={placeholder}
                className={cn('w-full p-2 border rounded', {
                    'border-red-500': errors[name],
                })}
            />
            {errors[name] && (
                <p className="text-red-500 text-xs">{errors[name]?.message}</p>
            )}
        </div>
    )

    return (
        <div className="h-full bg-purple-500 py-6 md:py-14 px-4 md:px-15 rounded-3xl md:rounded-5xl flex-col lg:flex-row flex justify-between gap-y-4">
            <div className="text-left space-y-1 md:space-y-4">
                <div className="uppercase text-xl md:text-5xl font-semibold text-white font-rubik">
                    Become an early supporter
                </div>
                <div className="text-cwhite-300 font-dmSans text-xs	md:text-[28px] leading-none">
                    ...of the SF chapter and receive lifelong benefits
                </div>
            </div>
            <div className="flex justify-center lg:relative">
                {isSuccess ? (
                    <SuccessCard
                        title="Payment Successful"
                        description="Your BERLINHOUSE citizenship is confirmed. We are excited to shape the next chapter of urban living, innovation & culture, together. Check your email inbox!"
                        className="max-w-[500px] min-h-[481px]"
                    >
                        <Button
                            variant="PRIMARY"
                            className="w-full uppercase"
                            size="sm"
                            onClick={() => navigate('/')}
                        >
                            Go to home page
                        </Button>
                    </SuccessCard>
                ) : (
                    <div className="rounded-3xl p-4 md:p-6 bg-white max-w-[500px] relative">
                        <div
                            className={cn(
                                'h-full w-full absolute inset-0 bg-white/[0.8] z-50',
                                {
                                    hidden: !loading,
                                }
                            )}
                        >
                            <div className="w-12 h-12 border-4 border-t-4 border-white border-t-purple-550 rounded-full animate-spin absolute inset-0 m-auto" />
                        </div>
                        <FormProvider {...formMethods}>
                            <form
                                className="text-xs md:text-base"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                }}
                                noValidate
                            >
                                <div className="space-y-2 md:space-y-4">
                                    <div className="text-black text-lg md:text-2xl font-medium font-rubik">
                                        Choose Your Plan
                                    </div>
                                    <div className="flex gap-x-1 md:gap-x-4">
                                        {frequencies.map((frequencyOption) => (
                                            <ToggleButton
                                                key={frequencyOption}
                                                label={frequencyOption}
                                                isSelected={
                                                    billingCycle ===
                                                    frequencyOption
                                                }
                                                onClick={() =>
                                                    setValue(
                                                        'billingCycle',
                                                        frequencyOption
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                    <div className="text-center text-[10px] md:text-xs text-cgray-400 font-dmSans">
                                        The subscription will begin with the
                                        venue's opening, est. for January 2025.
                                        A refundable deposit, equal to the
                                        displayed amount, will be held until
                                        that time
                                    </div>
                                    <div className="space-y-2 md:space-y-4">
                                        <div className="text-black text-base md:text-xl font-medium font-rubik">
                                            Citizenship
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-y-1 gap-x-2 h-full">
                                            {tiers.map((tierOption) => (
                                                <div
                                                    key={tierOption.tier}
                                                    className={`transition-all ease-in-out cursor-pointer flex-1 text-xs md:text-base text-center py-2 px-4 h-auto flex items-center justify-center rounded-md ${
                                                        tierOption.tier === tier
                                                            ? 'bg-cgray-900 border border-solid border-purple-350 text-purple-100'
                                                            : 'bg-white border border-solid border-gray-300 text-gray-300'
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setValue(
                                                            'tier',
                                                            tierOption.tier,
                                                            {
                                                                shouldValidate:
                                                                    false,
                                                            }
                                                        )
                                                    }}
                                                >
                                                    {tierOption.label}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="text-center text-[10px] md:text-xs text-cgray-400 font-dmSans pb-4">
                                        Yearly subscriptions come with a 20%
                                        discount & a limited physical access
                                        card, engraved with your membership
                                        title. The plan renews automatically
                                    </div>
                                </div>

                                <div className="space-y-4">
                                    <div className="space-y-2 md:space-y-4">
                                        <div className="text-black text-base md:text-xl font-medium font-rubik">
                                            Billing Information
                                        </div>
                                        <div className="flex gap-x-2">
                                            {renderFormField({
                                                name: 'firstName',
                                                placeholder: 'First Name',
                                            })}
                                            {renderFormField({
                                                name: 'lastName',
                                                placeholder: 'Last Name',
                                            })}
                                        </div>
                                        {renderFormField({
                                            name: 'email',
                                            placeholder: 'Email Address',
                                        })}
                                        <PhoneInputWithCountrySelect
                                            value={phoneNumber}
                                            onChange={(value) =>
                                                setValue(
                                                    'phoneNumber',
                                                    value?.toString()
                                                )
                                            }
                                            numberInputProps={{
                                                classNames: cn('w-full', {
                                                    'border border-red-500':
                                                        errors.phoneNumber ||
                                                        true,
                                                }),
                                            }}
                                            defaultCountry="US"
                                            placeholder="Phone Number (Optional)"
                                        />
                                        {errors.phoneNumber && (
                                            <p className="text-red-500 text-xs">
                                                {errors.phoneNumber.message}
                                            </p>
                                        )}
                                    </div>

                                    <div className="space-y-2 md:space-y-4">
                                        <div className="text-black text-base md:text-xl font-medium font-rubik">
                                            Payment Details
                                        </div>
                                        <CardElement
                                            options={cardElementOptions}
                                        />
                                    </div>

                                    <div className="space-y-2 md:space-y-4">
                                        <div className="text-black text-base md:text-xl font-medium font-rubik">
                                            Access Code
                                        </div>
                                        <div className="space-y-3 pb-4">
                                            {renderFormField({
                                                name: 'oneTimeCode',
                                                placeholder:
                                                    'Enter a 6-digit access code',
                                            })}
                                            <div className="font-dmSans text-gray-400  text-[10px] md:text-sm">
                                                Don&apos;t have a access code?
                                                Ask your friends or{' '}
                                                <a
                                                    href="https://onfjkjvxb5x.typeform.com/to/uIDTS9Y4"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    apply here
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div className="font-dmSans text-black text-xs md:text-base font-normal flex items-center justify-between">
                                        <span>Send me important updates</span>
                                        <ToggleSwitch
                                            checked={receiveNotifications}
                                            onChange={(value) =>
                                                setValue(
                                                    'receiveNotifications',
                                                    value
                                                )
                                            }
                                        />
                                    </div>

                                    {formError && (
                                        <div className="font-rubik text-md font-medium text-red-500">
                                            {formError}
                                        </div>
                                    )}

                                    <Button
                                        variant="PRIMARY"
                                        size="sm"
                                        className="w-full h-12 !font-medium text-sm md:text-base font-rubik uppercase"
                                        disabled={
                                            !stripe || loading || isValidating
                                        }
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Subscribe {price}/
                                        {billingCycle === 'monthly'
                                            ? 'month'
                                            : 'year'}
                                    </Button>
                                </div>
                                <div className="space-y-4">
                                    <div className="font-dmSans text-[10px] md:text-sm text-center text-gray-400 pt-2 md:pt-4">
                                        By{' '}
                                        <span className="font-bold">
                                            signing up
                                        </span>
                                        , you agree to our{' '}
                                        <a
                                            href="https://shrub-step-635.notion.site/Terms-Conditions-1118b4aa5603801ea445d89f5660f94b"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="no-underline text-gray-400 hover:text-purple-500"
                                        >
                                            Terms &amp; Conditions
                                        </a>
                                    </div>
                                    <div className="flex items-center justify-center gap-x-1">
                                        <div className="font-rubik text-sm text-gray-400">
                                            Powered by
                                        </div>{' '}
                                        <img
                                            src="/images/stripe-logo.svg"
                                            alt="Stripe"
                                        />
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Checkout
