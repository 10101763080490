// src/pages/Briefing.tsx
import React, { useEffect } from 'react';

const Briefing: React.FC = () => {
    useEffect(() => {
        window.location.href = "https://shrub-step-635.notion.site/Frontier-Tower-SF-berlinhouse-com-1138b4aa56038070b58ac88bcd20ed2f";
    }, []);

    return (
        <div className="loading-container">
            <div className="spinner"></div>
        </div>
    );
};

export default Briefing;
