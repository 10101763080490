import React from 'react'
import { ToggleButtonProps } from './types'
import cn from 'classnames'

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    label,
    isSelected,
    onClick,
}) => {
    return (
        <button
            className={cn(
                'font-rubik text-base font-normal flex items-center justify-center w-full px-6 py-2 rounded-md transition-colors duration-200 focus:outline-none uppercase text-xs md:text-base',
                {
                    'bg-cgray-900 border border-solid border-purple-350 text-purple-100':
                        isSelected,
                    'bg-white border border-solid border-gray-300 text-gray-300':
                        !isSelected,
                }
            )}
            onClick={onClick}
        >
            <span
                className={cn(
                    'mr-2 inline-flex items-center justify-center w-4 h-4 rounded-full aspect-square',
                    {
                        'border-2 border-indigo-600': isSelected,
                        'border border-gray-300': !isSelected,
                    }
                )}
            >
                {isSelected && (
                    <span className="w-2 h-2 rounded-full bg-indigo-600 aspect-square" />
                )}
            </span>
            {label}
        </button>
    )
}
