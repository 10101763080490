interface Config {
    baseUrl: string
    apiUrl: string
    stripePublicKey: string
    priceIdDigitalAmbassadorMonthly: string
    priceIdDigitalAmbassadorYearly: string
    priceIdStudentMonthly: string
    priceIdStudentYearly: string
    priceIdFoundingMemberMonthly: string
    priceIdFoundingMemberYearly: string
}

const config: Config = {
    baseUrl: 'https://berlinhouse.com',
    apiUrl: 'https://api.berlinhouse.com',
    stripePublicKey:
        'pk_live_51Q2NwMRuiWHaTKkDTxJ2ZmtrIMIrOptl9ZMmwYBW9RvNnJXIvlBxHrYXgT0BBOTboZQITWAyjtJpr7WUojtIL41200ppfScXnU',
    priceIdStudentMonthly: 'price_1Q6yQARuiWHaTKkDKzMIKBfb',
    priceIdStudentYearly: 'price_1Q6EQ5RuiWHaTKkDtKk0jTn5',
    priceIdDigitalAmbassadorMonthly: 'price_1Q6EPRRuiWHaTKkDBCadrop3',
    priceIdDigitalAmbassadorYearly: 'price_1Q6EPARuiWHaTKkDVbKVmCZa',
    priceIdFoundingMemberMonthly: 'price_1Q6EOtRuiWHaTKkDSZiNFmxh',
    priceIdFoundingMemberYearly: 'price_1Q6EOtRuiWHaTKkDSZiNFmxh',
}

export default config
