import { useRef } from 'react'
import { useFullWidthText } from '../../hooks'
import { GradientTextProps } from './types'
import cn from 'classnames'

export const GradientText = ({
    className,
    fullWidth,
    children,
}: GradientTextProps) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const { fontSize } = useFullWidthText({
        enabled: fullWidth,
        containerRef,
    })

    const webkitStroke = `${!fullWidth ? '4px transparent' : Math.max(1, fontSize / 25)}px transparent`

    return (
        <div className={cn('relative w-full', className)} ref={containerRef}>
            <span
                className="absolute inset-0 bg-gradient-to-r from-purple-100 to-purple-400 bg-clip-text text-transparent"
                style={{
                    WebkitTextStroke: webkitStroke,
                    WebkitBackgroundClip: 'text',
                    backgroundClip: 'text',
                }}
            >
                {children}
            </span>
            <span
                className="relative text-white"
                style={{
                    WebkitTextStroke: webkitStroke,
                }}
            >
                {children}
            </span>
        </div>
    )
}
