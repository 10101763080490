import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import 'react-phone-number-input/style.css'
import Landing from './pages/Landing'
import Checkout from './pages/Checkout'
import Success from './pages/Success'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import config from './config'
import { Header } from './components'
import { Footer } from './components'
import Briefing from './pages/Briefing'

const stripePromise = loadStripe(config.stripePublicKey)

const App: React.FC = () => {
    return (
        <div className={'root h-screen w-screen'}>
            <div className="page w-full p-6 md:p-11 flex flex-col h-full">
                <Header />
                {/* Main Content */}
                <div className="content grow w-full">
                    <Router>
                        <Elements stripe={stripePromise}>
                            <Routes>
                                <Route path="/" element={<Landing />} />
                                <Route
                                    path="/checkout"
                                    element={<Checkout />}
                                />
                                <Route path="/success" element={<Success />} />
                                <Route path="/briefing" element={<Briefing />} />
                            </Routes>
                        </Elements>
                    </Router>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default App
