import React from 'react'
import './Landing.css' // The CSS for styling
import { Button } from '../components'
import { useNavigate } from 'react-router-dom'

const Landing: React.FC = () => {
    const navigate = useNavigate()
    return (
        <div className="h-full relative">
            <div className="overflow-hidden relative bg-landing-page-bg bg-cover bg-center bg-no-repeat w-full h-[60vh] md:h-[750px] rounded-2.5xl lg:rounded-5xl relative bg-blend-luminosity bg-cgray-700">
                <div className="absolute -rotate-90 -left-10 md:-left-11 top-32 bg-purple-500 text-white p-4 md:p-6 font-rubik text-sm md:text-base font-medium md:font-semibold rounded-b-2xl">
                    Frontier Tower
                </div>
            </div>
            <div className="bg-purple-500 px-4 lg:px-17.5 py-6 lg:py-18 rounded-2.5xl lg:rounded-5xl absolute bottom-0 left-0 right-0 flex-col md:flex-row flex justify-between  flex-start md:items-center">
                <div className="space-y-1 md:space-y-4">
                    <div className="font-rubik font-semibold text-white uppercase text-base lg:text-4.5xl">
                        BECOME A FOUNDING CITIZEN
                    </div>
                    <div className="font-dmSans text-[10px] lg:text-2xl text-white">
                        Be part of the founding 300 laying the foundation of our social fabric
                    </div>
                </div>
                <Button
                    variant="SECONDARY"
                    className="font-rubik !font-medium w-20 lg:min-w-64 uppercase text-xs lg:text-2xl text-nowrap mt-4 md:mt-0 w-full md:w-fit"
                    onClick={() => navigate('/checkout')}
                >
                    Join us
                </Button>
            </div>
        </div>
    )
}

export default Landing
