import { GradientText } from '../GradientText'

export const Header = () => {
    return (
        <div className="font-rubik font-bold w-full">
            <GradientText className="uppercase text-[184px]" fullWidth>
                Berlinhouse
            </GradientText>
        </div>
    )
}
