import { SuccessCardProps } from './types'
import cn from 'classnames'

export const SuccessCard = ({
    title,
    description,
    className,
    children,
}: SuccessCardProps) => {
    return (
        <div
            className={cn(
                'bg-white p-6 rounded-3xl shadow-md text-center flex flex-col justify-center space-y-4',
                className
            )}
        >
            <div className="flex items-center justify-center">
                <img
                    src="/images/checkCircle.svg"
                    alt="Success"
                    className="w-[72px] h-[72px]"
                />
            </div>
            <div className="text-black text-2xl font-medium font-rubik">
                {title}
            </div>
            <div className="text-black text-base font-dmSans leading-normal">
                {description}
            </div>
            {children}
        </div>
    )
}
