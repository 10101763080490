import { ReactNode } from 'react'
import { InstagramIcon, LinkedinIcon, XIcon } from '../Icons'

export const Footer = () => {
    const Title = ({ children }: { children?: ReactNode }) => {
        return (
            <div className="font-rubik font-medium text-base md:text-2xl text-purple-900">
                {children}
            </div>
        )
    }

    const Content = ({ children }: { children?: ReactNode }) => {
        return (
            <div className="font-dmSans text-cwhite-200 text-xs lg:text-base font-light leading-normal">
                {children}
            </div>
        )
    }

    return (
        <div className='pb-6 md:pb-11 w-full'>
            <div className="pt-8 pb-16 lg:pb-11 lg:py-11 px-6 lg:px-16 rounded-2.5xl lg:rounded-5xl flex-col lg:flex-row flex justify-between bg-purple-100 min-h-60 lg:min-h-72 mt-4 md:mt-11 w-full relative space-y-6 lg:space-y-0 overflow-hidden">
                <div className="lg:max-w-[50%] z-10">
                    <Title>What is Berlinhouse</Title>
                    <Content>
                        We are a 16-floor innovation hub in the heart of San
                        Francisco, dedicated to advancing deep tech and frontier
                        technologies — the first node of our inter-city network
                        state.
                    </Content>
                </div>
                <div className="flex lg:gap-x-32 justify-between lg:justify-normal z-10">
                    <div className="">
                        <Title>Membership</Title>
                        <Content>
                            <a href='https://shrub-step-635.notion.site/Terms-Conditions-1118b4aa5603801ea445d89f5660f94b'
                                target='_blank' rel='noreferrer'
                                className='no-underline text-cwhite-200 hover:text-purple-500'>
                                Terms &amp; Conditions
                            </a>
                        </Content>
                        <Content>
                            <a href='https://shrub-step-635.notion.site/Privacy-Policy-1118b4aa560380e88768c75a215bccbc'
                                target='_blank' rel='noreferrer'
                                className='no-underline text-cwhite-200 hover:text-purple-500'>
                                Privacy Policy
                            </a>
                        </Content>
                    </div>
                    <div className="space-y-2 z-10">
                        <Title>Follow Us</Title>
                        <div className="flex gap-x-4">
                            <Content>
                                <a href='https://instagram.com/berlinhouse_sf'
                                    target='_blank' rel='noreferrer' className='no-underline text-cwhite-200'>
                                    <InstagramIcon className='w-4 h-4 hover:stroke-purple-500' />
                                </a>
                            </Content>
                            <Content>
                                <a href='https://x.com/berlinhouse_sf'
                                    target='_blank' rel='noreferrer' className='no-underline text-cwhite-200'>
                                    <XIcon className='w-4 h-4 hover:stroke-purple-500' />
                                </a>
                            </Content>
                            <Content>
                                <a href='https://www.linkedin.com/company/berlinhouse'
                                    target='_blank' rel='noreferrer' className='no-underline text-cwhite-200'>
                                    <LinkedinIcon className='w-4 h-4 hover:stroke-purple-500' />
                                </a>

                            </Content>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        maskImage: `url(/images/berlinhouse.svg)`,
                        maskSize: 'contain',
                        WebkitMaskImage: `url(/images/berlinhouse.svg)`,
                        WebkitMaskSize: 'contain',
                        maskRepeat: 'no-repeat',
                        WebkitMaskRepeat: 'no-repeat',
                        maskPosition: 'center',
                        WebkitMaskPosition: 'center',
                        backgroundColor: 'white',
                        width: '100%',
                    }}
                    className="absolute bottom-[-28%] md:bottom-[-25%] lg:bottom-[-34%] mx-auto left-0 right-0 h-[65%] lg:h-full"
                ></div>
            </div>
        </div>
    )
}
