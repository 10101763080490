import { ButtonHTMLAttributes, ReactNode } from 'react'

export enum ButtonVariant {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: keyof typeof ButtonVariant
    className?: string
    size?: 'md' | 'sm'
    children?: ReactNode
    loading?: boolean
}
