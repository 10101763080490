import { useEffect, useState } from 'react'

export const useFullWidthText = ({
    defaultFontSize = 184,
    containerRef,
    enabled,
}: {
    enabled?: boolean
    defaultFontSize?: number
    containerRef?: React.RefObject<HTMLElement>
}) => {
    const [fontSize, setFontSize] = useState(defaultFontSize)

    useEffect(() => {
        const resizeText = () => {
            const container = containerRef?.current

            if (!container) return

            let size = defaultFontSize
            container.style.fontSize = `${size}px`

            while (container.scrollWidth <= container.offsetWidth) {
                size += 1
                container.style.fontSize = `${size}px`
            }

            while (container.scrollWidth > container.offsetWidth) {
                size -= 1
                container.style.fontSize = `${size}px`
            }

            setFontSize(size)
        }

        if (enabled) {
            resizeText()
            window.addEventListener('resize', resizeText)
        }

        return () => window.removeEventListener('resize', resizeText)
    }, [containerRef, enabled, defaultFontSize]) // Add defaultFontSize as a dependency

    return {
        fontSize,
    }
}
