'use client'

import cn from 'classnames'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

interface ToggleSwitchProps {
    checked?: boolean
    onChange?: (checked: boolean) => void
}

export const ToggleSwitch = ({ checked, onChange }: ToggleSwitchProps) => {
    const [toggle, setToggle] = useState(checked)

    const refValueA = useRef<HTMLDivElement>(null)
    const refValueB = useRef<HTMLDivElement>(null)

    const [valueA, setValueA] = useState({ width: 0, left: 0 })
    const [valueB, setValueB] = useState({ width: 0, left: 0 })

    const getValueInfo = (ref: React.RefObject<HTMLDivElement>) => ({
        width: ref.current?.clientWidth || 0,
        left: ref.current?.offsetLeft || 0,
    })

    useLayoutEffect(
        () => setValueA(getValueInfo(refValueA)),
        [refValueA.current?.clientWidth]
    )
    useLayoutEffect(
        () => setValueB(getValueInfo(refValueB)),
        [refValueB.current?.clientWidth]
    )

    useEffect(() => {
        if (onChange) {
            onChange(!!toggle)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle])

    const switchLeft = toggle ? valueB.left : valueA.left

    return (
        <div
            className={cn(
                'w-fit flex items-center rounded-full cursor-pointer relative transition-all ease-in-out',
                {
                    'bg-white border border-solid border-gray-300': !toggle,
                    'bg-indigo-600 border border-solid border-indigo-600':
                        toggle,
                }
            )}
            onClick={() => setToggle(!toggle)}
        >
            <div
                style={{ left: `${switchLeft}px` }}
                className={cn(
                    'rounded-full transform duration-300 ease-in-out absolute w-3 h-3 transition-all ease-in-out',
                    {
                        'bg-white': toggle,
                        'bg-indigo-600': !toggle,
                    }
                )}
            />
            <div
                ref={refValueA}
                className={cn(
                    'transform-all duration-300 ease-in-out py-2 w-4 h-4'
                )}
            />
            <div
                ref={refValueB}
                className={cn(
                    'transform-all duration-300 ease-in-out py-2 w-4 h-4'
                )}
            />
        </div>
    )
}

export default ToggleSwitch
