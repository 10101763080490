import cn from 'classnames'
import { ButtonProps, ButtonVariant } from './types'

const ButtonVariantClassNameMap: Record<ButtonVariant, string> = {
    PRIMARY: 'bg-purple-500 text-white',
    SECONDARY: 'bg-cwhite-200 text-purple-100',
}

const ButtonVariantHoverClassNameMap: Record<ButtonVariant, string> = {
    PRIMARY: 'transition-all ease-in-out hover:bg-purple-550',
    SECONDARY: 'transition-all ease-in-out hover:bg-cwhite-300',
}

const ButtonSizeClassNameMap: Record<'md' | 'sm', string> = {
    md: 'text-2xl font-medium py-2.5 px-9',
    sm: 'text-base font-medium py-2 px-8',
}

const ButtonDisabledClassNameMap: Record<ButtonVariant, string> = {
    PRIMARY: '!text-gray-500 !bg-gray-200',
    SECONDARY: 'opacity-50',
}

export const Button = ({
    variant = ButtonVariant.PRIMARY,
    className,
    size = 'md',
    children,
    loading,
    ...props
}: ButtonProps) => {
    const variantClassName = ButtonVariantClassNameMap[variant]
    const sizeClassName = ButtonSizeClassNameMap[size]
    const disabledClassName = ButtonDisabledClassNameMap[variant]
    const hoverClassName = ButtonVariantHoverClassNameMap[variant]

    return (
        <button
            {...props}
            className={cn(
                'flex justify-center box-border font-normal leading-6 overflow-hidden rounded-lg w-fit h-fit',
                {
                    'pointer-events-none': props.disabled || loading,
                    [disabledClassName]: props.disabled,
                },
                sizeClassName,
                variantClassName,
                hoverClassName,
                className
            )}
        >
            <div
                className={cn('w-full h-full flex items-center justify-center')}
            >
                {children}
            </div>
        </button>
    )
}
